import React,{ useEffect, useState } from 'react'
import HomeSlider from '../components/HomeSlider';
import { Button, Form, ListGroup } from 'react-bootstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import './styling/Home.css';

export default function Home() {
    const [reg, setReg] = useState('DREAM CAR')
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();


    const isV2 = true;
    const navigateFindPage = (registration) => {
        if(!isV2){
            navigate(`/carfinder?reg=${registration}`);  
        } else{
            navigate(`/fullmotcheck?reg=${registration}`);
        }
    };

    useEffect(()=> {
      const wakeLambda = async () => {
          let response = await fetch(
              process.env.REACT_APP_BASE_URL + '/Ping/wake', {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              }
          }
          );
      }

      wakeLambda()
  }, [])

    let carNotfound = searchParams.get("carnotfound")

    return <div className='main'>
        <h1 className='fontlarge'>We've helped check over 10,000 vehicles</h1>
        <h1 className='fontsmall'>Get a check to ensure your car is safe and clear of any hidden finance.</h1>
        {/* <HomeSlider></HomeSlider> */}
        <Form.Control id="plate" class="form-control-lg" type="text" maxlength="8" onChange={event => setReg(event.target.value.split(" ").join(""))} placeholder="Enter your reg" />
        <h1></h1>
        <Button id = "drive-button" variant="success" onClick={() => navigateFindPage(reg)}><b>FREE CAR CHECK</b></Button>
        {carNotfound ? <b class = "carnotfound">Car not found</b> : null}
        <br></br>
        <a style={{ fontWeight: 'bold' }} href="ourdata">Where Does Our Data Come From?</a>
        <br></br>
        <h1 className='fontsmall'>Written off cars are worth between 20% to 50% less</h1>
        <h1 className='fontsmall'>1 in 6 cars have outstanding finance</h1>
        <h1 className='fontsmall'>With our checks you can find out</h1>
        
        <div className='sellingpoints'>
            <ListGroup variant="flush">
                <ListGroup.Item className="border-0">
                    <div className="d-flex justify-content-between">
                    <h6>Write Off Status</h6>
                    <h6>Mileage History</h6>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="border-0">
                    <div className="d-flex justify-content-between">
                    <h6>MOT History</h6>
                    <h6>Number Plate History</h6>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="border-0">
                    <div className="d-flex justify-content-between">
                    <h6>Number Of Keepers</h6>
                    <h6>Stolen Status</h6>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </div>
       
        <br></br>
        <h5><b><a href="https://www.carinformation.co.uk/fullcheck?reg=ef88c427-47f4-4d38-be4e-0c2cdbdd0581">View Our Sample Report</a></b></h5>
    </div>
}